<template>
  <div class="businessAdminRole">
    <div class="roleh">
      <span class="title">角色管理</span>
      <el-button
        type="primary"
        size="small"
        style="float: right"
        @click="createBusinessRole()"
        v-if="managerType != 0 || getAuth('businessRoleOperate')"
        >新增角色</el-button
      >
    </div>
    <div class="roletb">
      <el-table border :data="tableData" header-cell-class-name="table_header">
        <el-table-column
          property="role_name"
          label="角色"
          width="160"
        ></el-table-column>
        <el-table-column
          property="role_type_text"
          label="角色类型"
          width="160"
        ></el-table-column>
        <el-table-column label="权限">
          <template slot-scope="scope">
            {{ scope.row.auth_list_text }}
          </template>
        </el-table-column>
        <el-table-column
          property="statusText"
          label="状态"
          width="160"
        ></el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('businessRoleOperate')"
              @click="editBusinessRole(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <business-role-modal ref="businessRoleModal" />
  </div>
</template>

<script>
import businessRoleModal from "./components/business-role-modal.vue";
import { businessAllRoleList } from "@/api/businessManage.js";
import { getAuth } from "@/utils/index.js";
export default {
  name: "businessAdminRole",
  components: { businessRoleModal },
  data() {
    return {
      getAuth: getAuth,
      tableData: [],
      managerType: localStorage.getItem("managerType"),
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    async initData() {
     
      const { code, data } = await businessAllRoleList();
      if (code == 200) {
        this.tableData = data.map((item) => {
          item.statusText = item.status == 1 ? "正常" : "禁用";
          item.role_type_text =
            item.role_type == 1
              ? "商家"
              : item.role_type == 2
              ? "一级机构"
              : "主体";
          var auth_list_text = [];
          item.auth_list.map((val) => {
            auth_list_text.push(val.auth_title);
          });
          item.auth_list_text = auth_list_text.join("、");
          return item;
        });
      }
    },
    createBusinessRole() {
      this.$refs.businessRoleModal.isShow("add");
    },
    editBusinessRole(row) {
      this.$refs.businessRoleModal.isShow("edit", row);
    },
  },
};
</script>

<style lang="scss" scoped>
.businessAdminRole {
  .title {
    font-weight: 700;
    color: #515a6e;
    margin-bottom: 30px;
    font-size: 14px;
  }
  .roleh {
    margin-bottom: 30px;
  }
  .roletb {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
      font-size: 10px;
      .cell {
        padding: 0px 5px;
      }
    }
  }
}
</style>